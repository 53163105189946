<template>
  <Html v-bind="head.htmlAttrs || {}">
    <Head>
      <Title>{{ title }}</Title>
      <Link
        v-for="link in head.link"
        :id="link.id"
        :key="link.id"
        :rel="link.rel"
        :href="link.href"
        :hreflang="link.hreflang"
      />
      <Meta v-for="meta in head.meta" :id="meta.id" :key="meta.id" :property="meta.property" :content="meta.content" />
    </Head>
    <Body>
      <div
        class="page-wrapper "
      >
        <v-app class="background background--1">
          <v-navigation-drawer
            v-if="!isMobile"
            :model-value="!focusMode && !isMobile"
            class="sidebar"
            rail
            expand-on-hover
            app
            :rail-width="74"
          >
            <slot name="navigation" />
          </v-navigation-drawer>

          <v-app-bar
            app
            class="header"
            density="comfortable"
          >
            <v-app-bar-nav-icon
              v-if="isMobile"
              variant="text"
              color="color-primary"
              @click.stop="showHamburger = !showHamburger"
            />
            <v-app-bar-title>
              <mwa-logo
                v-if="!theme.current.value.dark"
                class="mr-10 logo--mbw"
                @click="navigateTo(localePath({'name': 'index'}))"
              />
              <mwa-logo-inv
                v-if="theme.current.value.dark"
                class="mr-10 logo--mbw--white"
                @click="navigateTo(localePath({'name': 'index'}))"
              />
              <muni-logo v-if="$i18n.locale === 'cs'" class="logo--muni logo--muni-cs" />
              <muni-logo-en v-else class="logo--muni" />
            </v-app-bar-title>
            <div v-if="!isMobile" class="d-flex theme__switch__container a-i-c j-c-c pr-12">
              <theme-switcher />
            </div>
            <v-menu
              v-if="!isMobile"
              :close-on-content-click="false"
              location="bottom"
            >
              <template #activator="{ props }">
                <v-btn
                  color="primaryInverse"
                  v-bind="props"
                  icon="mdi-cog"
                  class="header__btn"
                />
              </template>

              <v-card
                class="rounded-t-sm mt-2"
                color="navbarDropdown"
                min-width="250"
              >
                <v-row
                  class="pa-5 d-flex j-c-s-a"
                  no-gutters
                >
                  <v-col class="mr-2 mt-1 lang-switcher">
                    <lang-switcher />
                  </v-col>
                </v-row>
                <v-row class="px-5" no-gutters>
                  <v-col>
                    <v-switch
                      v-model="focusMode"
                      :label="$t(`settings.focus_mode`)"
                      inset
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-menu>
          </v-app-bar>

          <v-navigation-drawer
            :model-value="showHamburger"
            class="h-100"
            location="top"
            temporary
            :class="[smAndDown ? 'w-75' : 'w-66']"
          >
            <slot name="navigation" />
          </v-navigation-drawer>

          <v-main class="main-container">
            <v-container class="main-container-content" fluid>
              <slot
                class="content"
              />
            </v-container>
          </v-main>

          <slot name="after-main" />
          <!-- @vue-ignore -->
          <TheNotifications
            position="top right"
            width="500"
            pause-on-hover
          />
        </v-app>
      </div>
    </Body>
  </Html>
</template>

<script setup lang="ts">
import { useLocalStorage } from "@vueuse/core";
import { useLocale, useTheme, useDisplay } from "vuetify";
// @ts-ignore
import * as Sentry from "@sentry/vue";
import MwaLogo from "../assets/svg/mwa.svg";
// @ts-ignore
import MwaLogoInv from "../assets/svg/mwa-inv.svg";
// @ts-ignore
import MuniLogo from "../assets/svg/logo-muni-lf.svg";
import MuniLogoEn from "../assets/svg/logo-muni-lf-en.svg";
const userInfoStore = useUserInfoStore();
const localePath = useLocalePath();
const { t, locale, setLocale } = useI18n();
const route = useRoute();
const appConfig = useAppConfig();
const authStore = useAuthStore();
const { isMobile } = useIsMobile();
const { smAndDown } = useDisplay();

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true
});
const { current: vuetifyLocale } = useLocale();
const switchLocalePath = useSwitchLocalePath();
const theme = useTheme();

const preferredTheme = useLocalStorage("preferredTheme", "dark");
const focusMode = useLocalStorage("focusMode", false);
const showHamburger = ref<boolean>(false);

const title = computed<string>(() => {
  const part = route.meta.title ? t(route.meta.title as string) : "";
  return part ? `${appConfig.baseTitle} - ${part}` : appConfig.baseTitle;
});

onMounted(async () => {
  theme.global.name.value = preferredTheme.value;
  if (authStore.isLoggedIn) {
    await userInfoStore.loadUserInfoData();
    setUserLanguage();
  }
  if (window?.cookieBar) {
    window?.cookieBar?.changeLanguage(locale.value);
  }
  Sentry.setTag("page_locale", locale.value);
});

function setUserLanguage (): void {
  if (!userInfoStore.userData.language) {
    userInfoStore.userData.language = locale.value;
    userInfoStore.saveUserInfoData();
  } else {
    setLocale(userInfoStore.userData.language);
    vuetifyLocale.value = userInfoStore.userData.language;
    navigateTo(switchLocalePath(userInfoStore.userData.language), { replace: true });
  }
}

</script>

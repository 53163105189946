<template>
  <v-icon icon="mdi-weather-sunny" class="mr-2" />
  <v-switch
    v-bind="$attrs"
    v-model="theme.global.name.value"
    hide-details
    inset
    flat
    true-value="dark"
    false-value="light"
    density="comfortable"
    style="display: contents;"
    class="d-flex ma-auto"
  />
  <v-icon icon="mdi-weather-night" class="ml-2" />
</template>

<script setup lang="ts">
import { useTheme } from "vuetify";
import { useLocalStorage } from "@vueuse/core";

const theme = useTheme();

const preferredTheme = useLocalStorage("preferredTheme", "dark");

watch(() => theme.global.name.value, () => {
  preferredTheme.value = theme.global.name.value;
});

</script>

<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        :rounded="false"
        color="navbarDropdown"
        variant="flat"
        width="130"
        class="d-flex j-c-s-b"
      >
        <template #prepend>
          <component
            :is="getFlag(currentLocale?.code)"
            class="flag"
            width="25"
            height="25"
            style="position: relative;"
            :class="`flag-${ currentLocale?.code }`"
          />
        </template>
        {{ currentLocale?.name }}
      </v-btn>
    </template>
    <v-list class="lang-swither-v-list">
      <v-list-item
        v-for="availableLocale in availableLocales"
        :key="availableLocale.code"
        v-memo="[availableLocale.code === currentLocale?.code]"
        :value="availableLocale.code"
        class="langSwith--item d-flex j-c-s-b"
        color="navbarDropdown"
        bg-color="navbarDropdown"
        variant="flat"
        width="130"
        @click="changeLanguage(availableLocale.code)"
      >
        <template #prepend>
          <component
            :is="getFlag(availableLocale.code)"
            class="flag"
            width="25"
            height="25"
            style="position: relative;"
            :class="`flag-${availableLocale.code}`"
          />
        </template>
        <v-list-item-title>
          {{ availableLocale.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { useLocale } from "vuetify";
import * as Sentry from "@sentry/vue";

const { locale, locales, setLocale } = useI18n();
const { current: vuetifyLocale } = useLocale();
const switchLocalePath = useSwitchLocalePath();
const userInfoStore = useUserInfoStore();
const currentLocale = ref(
  (locales.value).find(i => (i.code as string) === locale.value)
);

const availableLocales = computed(() => {
  return (locales.value).filter(i => (i.code as string) !== locale.value);
});

function changeLanguage (lang: string): void {
  setLocale(lang);
  userInfoStore.userData.language = lang;
  userInfoStore.saveUserInfoData();
  if (process.client && window?.cookieBar) {
    window?.cookieBar?.changeLanguage(lang);
  }
  vuetifyLocale.value = lang;
  Sentry.setTag("page_locale", lang);
  navigateTo(switchLocalePath(lang), { replace: true });
}

function getFlag (name: string | undefined): ReturnType<typeof defineAsyncComponent> {
  return defineAsyncComponent(() =>
    import(`../assets/svg/flag-${name}.svg`)
      .catch(() => {
        // eslint-disable-next-line no-console
        console.warn(`Failed to load flag ${name}`);
        return { render: () => "" };
      })
  );
}

</script>

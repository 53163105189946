<template>
  <base-layout>
    <template #navigation>
      <v-list-item
        prepend-icon="mdi-account-circle"
        :title="userInfoStore.userName"
        :to="localePath({'name': 'profile-personal-data'})"
      />
      <div v-if="isMobile" class="d-flex justify-space-around">
        <div class="d-flex theme__switch__container a-i-c j-c-c">
          <theme-switcher />
        </div>
        <div class="d-flex lang-switcher a-i-c j-c-c">
          <lang-switcher />
        </div>
      </div>
      <v-divider />

      <v-list density="compact" nav>
        <template v-for="(item, index) in menuItems">
          <v-list-item v-if="!item.subItems ||(item.subItems && !item.subItems.length)" :key="`item-${index}`" :prepend-icon="item.icon" :title="$t(item.title)" :to="localePath({'name': item.linkPath})" />
          <v-list-group v-else :key="`group-${index}`">
            <template #activator="{ props }">
              <v-list-item
                v-bind="props"
                :prepend-icon="item.icon"
                :title="$capitalize($t(item.title))"
              />
            </template>

            <v-list-item
              v-for="(subItem, i) in item.subItems"
              :key="i"
              :title="$t(subItem.title)"
              :prepend-icon="subItem.icon"
              :to="localePath({'name': subItem.linkPath})"
            />
          </v-list-group>
        </template>
        <v-list-item v-if="authStore.isLoggedIn" prepend-icon="mdi-logout" :title="$capitalize($t('layout.menu.logout'))" @click="logout" />
      </v-list>
    </template>

    <v-dialog
      v-if="authStore.isLoggedIn"
      v-model="askForAddressBy"
      persistent
      max-width="500px"
    >
      <v-card
        class="pa-5"
        max-width="500px"
      >
        <p>{{ $t("pages.index.tell_us_more") }}</p>
        <v-form
          v-model="personalFormValid"
          lazy-validation
          @submit.prevent="savePersonalData"
        >
          <v-text-field
            v-model="userInfoStore.userData.first_name"
            :label="$t('profile.personal.name')"
            :rules="[rules.required]"
            :disabled="userInfoStore.dataSaving"
          />
          <v-text-field
            v-model="userInfoStore.userData.last_name"
            :label="$t('profile.personal.surname')"
            :rules="[rules.required]"
            :disabled="userInfoStore.dataSaving"
          />
          <v-text-field
            v-model="userInfoStore.userData.address_by"
            :label="$t('profile.personal.address_by')"
            :rules="[rules.required]"
            :disabled="userInfoStore.dataSaving"
          />
          <v-btn
            block
            :loading="userInfoStore.dataSaving"
            :disabled="!personalFormValid"
            type="submit"
          >
            {{ $t("common.save") }}
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
    <slot />
  </base-layout>
</template>

<script setup lang="ts">
import { notify } from "@kyvg/vue3-notification";
import { VForm } from "vuetify/components";
const localePath = useLocalePath();
const authStore = useAuthStore();
const userInfoStore = useUserInfoStore();
const { rules } = useFormValidators();
const { t } = useI18n();
const { isMobile } = useIsMobile();

type MenuItem = {
  title: string
  icon: string
  linkPath?: string
  subItems?: MenuItem[]
}

// fields will get translated later - only like this full reactivity is ensured - maybe Vue bug?
const menuItems = reactive<MenuItem[]>([
  { title: "layout.menu.dashboard", icon: "mdi-home", linkPath: "index" },
  {
    title: "layout.menu.profile",
    icon: "mdi-account",
    subItems: [
      { title: "layout.menu.profile_personal_data", icon: "mdi-shield-account", linkPath: "profile-personal-data" },
      { title: "layout.menu.profile_anonymity_settings", icon: "mdi-account-cog", linkPath: "profile-anonymity-settings" }
    ]
  },
  { title: "layout.menu.my_results", icon: "mdi-chart-bar", linkPath: "my-results-here-i-am" },
  { title: "layout.menu.my_library", icon: "mdi-book", linkPath: "mental-health-care" },
  { title: "layout.menu.need_help", icon: "mdi-chat-question", linkPath: "need-help" },
  { title: "layout.menu.technical_support", icon: "mdi-face-agent", linkPath: "technical-support" }
]);

const askForAddressBy = ref<boolean>(false);
const personalFormValid = ref<boolean>(true);

if (!userInfoStore.isDataLoaded) {
  await userInfoStore.loadUserInfoData();
}
if (userInfoStore?.userData?.is_company_admin) {
  menuItems.splice(5, 0,
    {
      title: "layout.menu.company",
      icon: "mdi-domain",
      subItems: [
        { title: "layout.menu.company_results", icon: "mdi-chart-bar", linkPath: "company-results" },
        { title: "layout.menu.company_settings", icon: "mdi-domain", linkPath: "company-settings" },
        { title: "layout.menu.company_admins", icon: "mdi-account-cog", linkPath: "company-employee-settings" },
        { title: "layout.menu.company_departments", icon: "mdi-domain", linkPath: "company-departments" }
      ]
    }
  );
}

onMounted(() => {
  askForAddressBy.value = !userInfoStore?.userData?.address_by;
});

// const focusMode = useLocalStorage("focusMode", false);

async function savePersonalData () {
  if (!personalFormValid.value) {
    return;
  }
  await userInfoStore.saveUserInfoData();
  if (userInfoStore.isDataLoaded && !!userInfoStore?.userData?.address_by) {
    notify({
      type: "success",
      title: t("common.success"),
      text: t("common.saved")
    });
    askForAddressBy.value = false;
  }
}

async function logout () {
  await authStore.logout();
  navigateTo(localePath({ name: "login" }));
}
</script>
